<template>
  <div class="statement-container">
    <div class="query-form" v-if="isForm">
      <div class="title">{{ tableTitle }}</div>
      <el-form
        ref="statementFormRef"
        :model="statementFormData"
        label-position="top"
        class="statement-form"
      >
        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item prop="project_id" label="选择项目">
              <el-select
                v-model="statementFormData.project_id"
                placeholder="请选择项目"
                clearable
                multiple
                collapse-tags
              >
                <el-option
                  v-for="item in projectOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="pay_type_id" label="收费方式">
              <el-select
                v-model="statementFormData.pay_type_id"
                placeholder="请选择收费方式"
                clearable
                multiple
              >
                <el-option
                  v-for="item in chargeTypeOptions"
                  :key="item.level"
                  :label="item.value"
                  :value="item.level"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item prop="chargeTime" label="缴费时间范围">
              <el-date-picker
                v-model="statementFormData.chargeTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="footer">
        <el-button
          @click="handleReset"
          type="primary"
          class="cancle"
          plain
          round
          >重置条件</el-button
        >
        <el-button class="confirm" @click="handleSearch" round
          >确认查询</el-button
        >
      </div>
    </div>

    <div v-if="!isForm" class="statement-table">
      <ContentTitle>{{ tableTitle }}</ContentTitle>
      <Wfilter
        :filterConfig="filterConfig"
        @print="handlePrint"
        @export="handleExport"
      >
        <div>
          <el-button
            @click="handleOpenQuery"
            type="primary"
            class="cancle"
            plain
            round
            >重新查询</el-button
          >
        </div>
      </Wfilter>
      <Wtable
        :columns="columns"
        :pagination="pagination"
        :tableData="tableData"
        @currentChange="currentChange"
        @sizeChange="sizeChange"
      ></Wtable>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, reactive } from "vue";
import { print, exportExcel } from "@/utils/common.js";
import { ElMessage } from "element-plus";
import dayjs from "dayjs";
import Wtable from "@/components/wTable/wTable.vue";
import Wfilter from "@/components/wFilter/wFilter.vue";
import ContentTitle from "@/components/contentTitle/contentTitle.vue";
import config from "./statement.json";
import API from "@/plugins/api.js";
export default {
  components: {
    Wtable,
    Wfilter,
    ContentTitle,
  },
  setup() {
    const isForm = ref(true);
    const filterConfig = reactive(config.filterConfig);
    const pagination = config.tableConfig.pagination;
    const columns = config.tableConfig.list;
    const projectOptions = ref([]);
    const chargeTypeOptions = ref([]);
    const page = ref("");
    const tableData = ref([]);
    const tableTitle = ref("停车收费统计表");
    const statementFormData = ref({
      project_id: [],
      pay_type_id: [],
      chargeTime: [],
      s_time: "",
      e_time: "",
    });
    function fetchData() {
      const lastParmas = {
        page: page.value,
        limit: pagination.pageSize,
        ...statementFormData.value,
      };
      API.getStatement(lastParmas).then((res) => {
        pagination.total = res.total;
        tableData.value = res.data;
      });
    }
    onMounted(() => {
      API.getProjectOptions({}).then((res) => {
        if (res.length) {
          projectOptions.value = res;
        }
      });
      API.getChargeWayOptions({}).then((res) => {
        if (res.length) {
          chargeTypeOptions.value = res;
        }
      });
    });

    const currentChange = (v) => {
      page.value = v;
      fetchData();
    };
    const sizeChange = (v) => {
      pagination.pageSize = v;
      fetchData();
    };
    const statementFormRef = ref(null);

    function handleSearch() {
      if (statementFormRef.value) {
        if (statementFormData.value["chargeTime"].length) {
          if (
            dayjs(statementFormData.value["chargeTime"][0]).unix() >
            dayjs(statementFormData.value["chargeTime"][1]).unix()
          ) {
            statementFormData.value["e_time"] = dayjs(
              statementFormData.value["chargeTime"][0]
            ).format("YYYY-MM-DD");
            statementFormData.value["s_time"] = dayjs(
              statementFormData.value["chargeTime"][1]
            ).format("YYYY-MM-DD");
          } else {
            statementFormData.value["e_time"] = dayjs(
              statementFormData.value["chargeTime"][1]
            ).format("YYYY-MM-DD");
            statementFormData.value["s_time"] = dayjs(
              statementFormData.value["chargeTime"][0]
            ).format("YYYY-MM-DD");
          }
        }
        fetchData();

        isForm.value = false;
      }
    }
    function handleReset() {
      statementFormData.value = {
        project_id: [],
        pay_type_id: [],
        chargeTime: [],
        s_time: "",
        e_time: "",
      };
    }
    function handleOpenQuery() {
      isForm.value = true;
    }
    const propertiesConfig = ref([
      { field: "p_name", displayName: "项目名称" },
      { field: "allmoney", displayName: "应收金额" },
      { field: "money", displayName: "实收金额" },
      { field: "yhmoney", displayName: "减免金额" },
      { field: "re_proportion", displayName: "减免占比率(%)" },
    ]);
    function handlePrint() {
      print(tableTitle.value, tableData.value, propertiesConfig.value);
    }
    function handleExport() {
      try {
        exportExcel(tableTitle.value, tableData.value, propertiesConfig.value);
        ElMessage.success({
          message: "导出成功！",
        });
      } catch (e) {
        ElMessage.error({
          message: "导出失败！",
        });
      }
    }
    return {
      statementFormRef,
      statementFormData,
      projectOptions,
      chargeTypeOptions,
      handleSearch,
      handleReset,
      isForm,
      filterConfig,
      currentChange,
      pagination,
      tableData,
      columns,
      handleOpenQuery,
      sizeChange,
      handlePrint,
      handleExport,
      tableTitle,
    };
  },
};
</script>

<style lang='scss'>
.statement-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  .el-button {
    padding: 12px 40px;
    background-color: var(--theme-color);
  }
  .cancle {
    border: 2px solid var(--theme-color);
    padding: 10px 40px;
    color: var(--theme-color);
    background-color: #ffffff !important;
    &:hover {
      background-color: var(--theme-color) !important;
      color: #fff;
    }
  }
  .statement-table {
    width: 100%;
    height: 100%;
  }
  .query-form {
    width: 780px;
    background-color: #ffffff;
    border-radius: 10px;
    border: solid 1px #e6e6e6;
    padding: 32px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    .title {
      font-size: 18px;
      color: var(--text-color);
      text-align: justify;
      font-weight: normal;
    }
    .statement-form {
      padding: 50px 0 60px 0;
    }
    .footer {
      display: flex;
      padding: 0 180px;
      justify-content: space-between;

      .confirm {
        color: #ffffff;
      }
    }
  }
}
</style>